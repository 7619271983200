import {createStore} from "vuex";
import Vue from 'vue';

const store = createStore({
    state() {
        return{
            basedomain:'https://dev.autoclosing.ai/api',
            // basedomain:'http://localhost',
            token:localStorage.getItem("access-token"),
            t_verify: localStorage.getItem("tokenAvailable"),
            user_log:localStorage.getItem("user-name"),
            userId: localStorage.getItem("accseqnm"),
            userRole: localStorage.getItem("accountRole"),
            userPeriod: localStorage.getItem("billingPeriod"),
            currentDate:localStorage.getItem("currentDate"),
            webStatus: true,
            moduleResult:{
                DSD : null,
                DSD_BR : null,
                DSD_AUTO: null,
                LEASE : null
            },

        }
    },

    mutations: {

        setUserToken: (state, payload) => {

            const now = new Date();
            const cd = now.toISOString().slice(0, 10); // 'YYYY-MM-DD' 형식
            const billingPeriod = payload.data.data.billingPeriod;
            const formattedBillingPeriod = billingPeriod
                ? billingPeriod.split(' ')[0] // ' ' 기준으로 분리 후 날짜 부분만 가져오기
                : null;
            // state.token = payload;
            localStorage.setItem("access-token", payload.data.data.token);
            localStorage.setItem("user-name", payload.data.data.userName);
            localStorage.setItem("tokenAvailable", payload.data.data.tokenExpire);
            localStorage.setItem("accseqnm", payload.data.data.accountSeqNm);
            localStorage.setItem("mobileAuth", payload.data.data.mobileAuth);
            localStorage.setItem("accountRole", payload.data.data.accountRole);
            localStorage.setItem("billingPeriod", formattedBillingPeriod);
            localStorage.setItem("currentDate",cd)
            window.location.href = '/'

        },
        setModuleResult:(state,result) => {

            if(result.moduleType)
            {
                if(result.moduleType === "DSD")
                {
                    state.moduleResult.DSD = result
                }else if(result.moduleType === "DSD_BR")
                {
                    state.moduleResult.DSD_BR = result
                    // console.log(state.moduleResult.DSD_BR)
                }else if(result.moduleType === "DSD_AUTO")
                {
                    state.moduleResult.DSD_AUTO = result
                    // console.log(state.moduleResult.DSD_AUTO)
                }else if(result.moduleType === "LEASE")
                {
                    state.moduleResult.LEASE = result
                }
            }
        },


    },
    actions:{

        Userlogin: ({commit},payload) => {
            commit("setUserToken",payload);
        },
        setModuleResult: ({commit},result) => {
            commit("setModuleResult",result);
        },


    },
    getters:{
        leaseResult: (state) => {
            return state.moduleResult.LEASE
        },
        dsdResult: (state) => {
            return state.moduleResult.DSD
        },
        dsdBR_Result: (state) => {
            // console.log('dsdBR_Result', state.moduleResult.DSD_BR)
            return state.moduleResult.DSD_BR
        },
        dsdAuto_Result: (state) => {
            // console.log('dsdAuto_Result', state.moduleResult.DSD_AUTO)
            return state.moduleResult.DSD_AUTO
        },
        moduleResult: (state) => state.moduleResult,
        userToken: (state) => {
            //현재시간 가져요기
            let timezoneOffset = new Date().getTimezoneOffset() * 60000;
            let timezoneDate = new Date(Date.now() - timezoneOffset);
            let C_time = new Date (timezoneDate.toISOString().substring(0, 19))
            // 만료일자 가져온 후 5분 이전으로 만료시간 조정
            const time = state.t_verify
            const givenDate = new Date(time);
            const resultDate = new Date(givenDate);
            resultDate.setMinutes(resultDate.getMinutes() - 5);

            //만료 시점 계산
            let dateA = new Date(C_time);
            let dateB = new Date(resultDate);

            if (dateA < dateB) {
                //만료시점을 지나지 않음
               return state.token
            } else {
               return null
            }
        }
    }
});

export default store;