<template>
  <div>
    <div id="board_title" class="row">
      <div id="main_title">
        리스회계처리 자동화<sup><i> v1.0.3 </i></sup>
      </div>
      <div id="sub_title">
        작성된 리스 양식의 세부내용을 확인 하여 산출물을 작성합니다.
      </div>
    </div>

    <div class="row">

      <div class="Main_Contents" >
      <div style="margin-bottom:60px;">아래 [리스자료 UPLOAD] 버튼을 클릭하여, 작성완료된 리스 양식 자료를 UPLOAD 합니다.<br>
        발행 완료 된 산출물은 등록된 USER MAIL 계정으로 송부 됩니다. </div>
      <div class="l_menus" v-if="workingStatus==='EMPTY'|| workingStatus==='SUCCESS'|| workingStatus==='ERROR' || workingStatus==='FAIL' || workingStatus===''">
        <div v-if="leaseStat ==='ENABLE'">
          <form method="POST" enctype="multipart/form-data" id="leaseForm">

            <label  id="up_btn" class="guidebox" @click.prevent="handleFileClick">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" id="up_btn_icon" fill="currentColor" class="icons" viewBox="0 0 16 16">
                <path d="M8.5 11.5a.5.5 0 0 1-1 0V7.707L6.354 8.854a.5.5 0 1 1-.708-.708l2-2a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 7.707V11.5z"/>
                <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z"/>
              </svg><p>리스자료 UPLOAD</p>
            </label>

            <input class="file" type="file" id="ls-file" accept=".xls,.xlsx" name="multipartFile" @change="upload" ref="fileInput">
          </form>
        </div>

      </div>

      <div class="l_menus" v-if="workingStatus==='PENDING'|| workingStatus==='IDLE'|| workingStatus==='PROCESSING' && leaseStat ==='ENABLE'">
        <div>
            <label for="ls-file" id="up_btn" class="guidebox disabled">
              <img class="loading" src="../images/loading.gif"><p>작업을 처리중 입니다...</p>
            </label>

            <input class="file" type="file" id="ls-file" accept=".xls,.xlsx" name="multipartFile" @change="upload" disabled>

        </div>
      </div>

        <div class="l_menus" v-if="leaseStat ==='DISABLE'">
          <div>
            <label for="ls-file" id="up_btn" class="guidebox disabled">
              <img class="loading" src="../images/loading.gif"><p>현재 기능 점검중입니다...</p>
            </label>

            <input class="file" type="file" id="ls-file" accept=".xls,.xlsx" name="multipartFile" @change="upload" disabled>

          </div>
        </div>

      <!--산출 성공-->
      <div class="finished_run"  v-if="workingStatus==='SUCCESS'" >
        <div>
          <span><span style="color:#0e427c">{{user}}</span>님의 최종 작업 내역입니다.</span>
        </div>
        <div class="run_detail">
          작업 일자: <span style="color:#0e2238">{{workingDate}}</span>
          <a class="run_btn" :href="lastUploadFile">입력 파일</a>
          ||<a class="run_btn" :href="lastOutputFile">산출 파일</a>
        </div>
        <div style="color:#0c83e1;" >
          <span>*입력파일과 산출파일은 최종 작업 시 입력 및 전달된 파일과 동일 합니다.</span>
        </div>
      </div>

      <!--      산출물 검증중-->
      <div class="finished_run"  v-if="workingStatus==='IDLE'" >
        <div>
          <span>현재 <span style="color:#0e427c">{{user}}</span>님의 검증중인 작업이 있습니다.</span>
        </div>
        <div class="run_detail">
          요청 일자: <span style="color:#0e2238">{{workingDate}}</span>
          <a class="run_btn" :href="lastUploadFile">입력 파일</a>
        </div>
        <div style="color:#0c83e1;" >
          <span>*작업이 끝난 후 다시 업로드 가능합니다. 잠시만 기다려 주세요.</span>
        </div>
<!--        <button class="btn btn-success resetbtn" @click="reset_form">작업 새로고침</button>-->
      </div>

        <!--      산출물 대기중-->
        <div class="finished_run"  v-if="workingStatus==='PENDING'" >
          <div>
            <span>현재 <span style="color:#0e427c">{{user}}</span>님의 대기중인 작업이 있습니다.</span>
          </div>
          <div class="run_detail">
            요청 일자: <span style="color:#0e2238">{{workingDate}}</span>
            <a class="run_btn" :href="lastUploadFile">입력 파일</a>
          </div>
          <div style="color:#0c83e1;" >
            <span>*작업이 끝난 후 다시 업로드 가능합니다. 잠시만 기다려 주세요.</span>
          </div>
<!--          <button class="btn btn-success resetbtn" @click="reset_form">작업 새로고침</button>-->
        </div>

        <!--      산출물 처리중-->
        <div class="finished_run"  v-if="workingStatus==='PROCESSING'" >
          <div>
            <span>현재 <span style="color:#0e427c">{{user}}</span>님의 처리중인 작업이 있습니다.</span>
          </div>
          <div class="run_detail">
            요청 일자: <span style="color:#0e2238">{{workingDate}}</span>
            <a class="run_btn" :href="lastUploadFile">입력 파일</a>
          </div>
          <div style="color:#0c83e1;" >
            <span>*작업이 끝난 후 다시 업로드 가능합니다. 잠시만 기다려 주세요.</span>
          </div>
<!--          <button class="btn btn-success resetbtn" @click="reset_form">작업 새로고침</button>-->
        </div>

      <!--      실행에러 01 -->
      <div class="finished_run"  v-if="workingStatus==='ERROR'" >
        <div>
          <span><span style="color:#0e427c">{{user}}</span>님의 최종 작업 중 오류가 발생하였습니다.</span>
        </div>
        <div class="run_detail">
          요청 일자: <span style="color:#0e2238">{{workingDate}}</span>
          <a class="run_btn" :href="lastUploadFile">입력 파일</a>
        </div>
        <div style="color:#0c83e1;" >
          <span>*자료를 재업로드하여 결과를 확인하세요.</span>
        </div>

      </div>

      <!--      실행에러 02 -->
      <div class="finished_run"  v-if="workingStatus==='FAIL'" >
        <div>
          <span><span style="color:#0e427c">{{user}}</span>님의 입력 내용에 오류가 있습니다.</span>
        </div>

        <div v-if="failMsg">
          <ul>
            <li v-for="(msg,index) in failMsg" :key="index">{{msg}}</li>
          </ul>
        </div>

        <div class="run_detail">
          요청 일자: <span style="color:#0e2238">{{workingDate}}</span>
          <a class="run_btn" :href="lastUploadFile">입력 파일</a>
        </div>

        <div style="color:#0c83e1;" >
          <span>*입력내용을 확인하시고 재업로드하여 결과를 확인하세요.</span>
        </div>

      </div>


      <div class="l_menus guide_pdf" style="margin-bottom: 10px;">
        <router-link to="/svcs/leasef" >
          <p>양식자료 PAGE</p>
        </router-link>
      </div>
      새로 작성한 양식자료가 필요한 경우 리스회계 양식 PAGE에서
      <br>다시 다운로드 하실 수 있습니다.<span style="font-size: 12px; font-weight: 800;">(클릭하여 PAGE 이동)</span>
    </div>
      <div class="Samples col-sm-5 mb-3 mb-sm-0">

<!--          <Carousel>-->
<!--            <Slide v-for="slide in slides" :key="slide">-->
<!--              <div class="carousel__item">-->
<!--                <img class="slideImg" :src="slide.img" /></div>-->
<!--            </Slide>-->

<!--            <template #addons>-->
<!--              <Navigation />-->
<!--              <Pagination />-->
<!--            </template>-->
<!--          </Carousel>-->

      </div>
    </div>
    <!--failMsg 모달폼-->
    <button type="button"  style="display: none" id="fail_Msg_modal" class="btn btn-primary detail_btn" data-bs-toggle="modal" data-bs-target="#fail_Msg">메세지</button>
    <div class="modal fade" id="fail_Msg" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog" style="max-width: max-content; margin:auto">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="exampleModalLabel">Lease Fail Message</h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body" style="padding-right: 33px;">
            <ul>
              <li v-for="(msg,index) in failMsg" :key="index">{{msg}}</li>
            </ul>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">확인</button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { Carousel, Navigation, Pagination, Slide } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'
import {computed, getCurrentInstance, watch} from "vue";
import router from "@/router";


export default {
  name: "lease_run",
  setup(){
    const instance = getCurrentInstance(); // 현재 컴포넌트 인스턴스 가져오기
    const proxy = instance.proxy; // `data`에 접근하려면 `instance.proxy`를 사용합니다.

    const moduleResult = computed(() => {
      return $store.getters.leaseResult
    })

    watch(moduleResult,(result) => {

      if(result.resultType === 'FAIL')
      {
        if(result.failMsg)
        {
          proxy.failMsg = result.failMsg
          // alert(result.failMsg.join('\n'))
          // console.log("fail")
          $('#fail_Msg_modal').click()

        }
      }
      proxy.workingStatus = result.resultType
      setTimeout(() => {
        proxy.getUserWork(); // `methods`의 getDsdWork 호출
      }, 1500); // 1500밀리초(1.5초) 지연
    })
  },
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  data(){
    return {
       failMsg:null,
       finished : true,
       workingDate:'',
       workingStatus:'',
       lastUploadFile:'',
       lastOutputFile:'',
       user: $store.state.user_log,
       leaseStat: null,
       leaseFree: null,
       slides: [{img:require("@/components/CommonPage/images/samples/test.gif")},{img:require("@/components/CommonPage/images/loading.gif")},],
    }
  },
  mounted(){
    this.webStat()
    if($store.state.token != null){
    this.getUserWork()
    }
    else{
      this.workingStatus='';
    }
  },

  methods:{
    webStat(){
      const t = this
      const url = '/module/settingInfo'
      const utoken =$store.state.token != null ? true : false;
      const getparam = {
      }
      $api.getApiData(url,utoken,successStat,errorStat,getparam);
      function successStat (res) {
        const mstat = res.data.data
        t.leaseStat = mstat.lease
        t.leaseFree = mstat.leaseFree
      }
      function errorStat (error) {
        alert("오류가 발생하였습니다. 페이지를 새로고침 해주세요.")
      }
    },
    getUserWork: function() {
      // const t=this
      const url = '/lease/leaseStatus'
      const utoken =$store.state.token != null;

      const successStat = (res) => {
        const Status= res.data.data
        this.workingStatus = Status.taskStatus;
        this.workingDate = Status.createDate;
        this.lastUploadFile = Status.inputFilePath;
        this.lastOutputFile = Status.outputFilePath;
      }

      $api.getApiData(url,utoken,successStat,errorStat);


      function errorStat (error) {
        alert("유저 작업 정보를 가져오지 못했습니다. 페이지를 새로고침 해 주세요.")

      }

    },
    handleFileClick() {
      // this.$gtag.event('LeaseRun(U)Click',{
      //   event_category: 'Clicks',
      //   event_label: 'Counts',
      //   value: 'Lease Upload'
      // })
      const t = this
      let a = localStorage.getItem("access-token");
      if (a != null) {
        let b = localStorage.getItem("mobileAuth");
        if (b === "true") {
          if(t.leaseFree){
            this.$refs.fileInput.click();
          } else{
            alert("이 기능은 유료 결제 서비스입니다.");
          }// 조건이 만족되면 파일 선택 창 열기
        } else {
          alert("본인 인증이 필요한 기능입니다. [마이페이지]에서 본인인증을 완료해 주세요");
          window.location.href = "/accpage/myinfo";
        }
      } else {
        alert("로그인 정보가 없습니다. 로그인 페이지로 이동합니다.");
        window.location.href = "/login";
      }
    },

    upload: function (event) {
       this.uploadLease(event)
    },

    uploadLease(event){
      const file = event.target.files[0];
      const url = '/lease/uploadLeaseFile'
      const utoken =$store.state.token != null;
      const inputdata ={
        "inputFile": file
      }

      if(file)
      {
        $api.uploadApiData (url,utoken,successStat,errorStat,inputdata);
        this.workingStatus = 'PENDING'
        this.$refs.fileInput.value = '';
      }

      function successStat (res) {
        // alert('파일을 업로드 하였습니다.');


      }
      function errorStat (error) {
       alert(error.response.data.message)
        window.location.reload()

      }
    },


  }
}
</script>

<style scoped>
.carousel{
  width:100%;
  height:100%;
}
.carousel__item {
  min-height: 200px;
  width: 100%;
  height: 380px;
  color: var(--vc-clr-white);
  font-size: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel__item img {
  width: 90%;
  border: 1px solid #d1d1d1;
}

.carousel__slide {
  padding: 10px;
}

.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  border: 5px solid white;
}


.guide_pdf{
  margin-bottom:10px;
  background-color: #0e2238;
  width:120px; height:30px;
  text-align:center;
  padding-top: 4px;
  color:white;
  border-radius: 20px;
  cursor: pointer;
}

.guide_pdf p:hover {
  color:#c6cfdd !important;
}
.l_menus{
  margin-bottom: 65px;
  font-size: 14px;
}


.icons{
  display: flex;
  width: 53px;
  background-color: #fff;
  height: 44px;
  padding: 6px;
  margin: auto 8px;
  border-radius: 25px;
}

.guidebox{
  display: flex;
  background-color: #dee2e6;
  width:265px;
  height:55px;
  border-radius: 35px;
  margin-bottom: 10px;
}

.disabled{
  display: flex;
  background-color: #cbd1d7;
  width:265px;
  height:55px;
  border-radius: 35px;
  margin-bottom: 10px;
}

.guidebox p {
  font-weight: 800;
  font-size: 13px;
  margin: auto 20px;

}

.disabled p {
  font-weight: 800;
  font-size: 13px;
  margin: auto 20px;
  color: #29486b;
}

.file {display: none;}
#leaseForm label{
  cursor:pointer;
}

.finished_run {
  border-top: 1px solid #e9ecef;
  border-bottom: 1px solid #e9ecef;
  max-width: max-content;
  margin: 40px 0px;
  padding: 10px 20px 10px 10px;
  background-color: #f5f5f5;
  border-radius: 7px;
}

.l_menus a{
  text-decoration: none;
}

.resetbtn {
  font-size: 14px;
  border-radius: 25px;
  width: 61px;
  padding-top: 3px;
  margin-left: 2px;
  background-color: transparent;
  color: black;
  padding-top: 7px;
  margin-top: 7px;
  width: 130px;
}

.run_detail{
  color:grey;
}
.run_btn{
  font-size: 12px;
  background-color: #0e2238;
  color: white;
  text-decoration: none;
  padding: 5px 15px;
  border-radius: 30px;
  line-height: 45px;
  margin-left: 10px;
  margin-right: 6px;
}

.loading{
  width: 49px;
  height: 46px;
  border-radius: 42px;
  padding: 1px;
  margin-top: 5px;
  margin-left: 7px;
}

.modal {
  --bs-modal-width:500px;
  --bs-modal-border-radius: 4px;
}

.modal-title{
  font-family: 'Pretendard-Regular';
}

.modal-header {
  height: 40px;
}
.modal-header h1 {
  margin-top: 0px;
  font-size: 15px !important;
}
.modal-body h4 {
  font-size: 17px;
  font-weight: bold;
}
.modal-footer {
  height: 40px;
  padding: 4px;
}
.modal-footer button {
  height: 26px;
  padding: 2px;
  width: 84px;
}

</style>