<template>
  <div>
    <div id="board_title" class="row">
      <div id="main_title">
        리스자료 양식<sup><i> v1.0.3 </i></sup>
      </div>
      <div id="sub_title">
        리스계산 자료 작성 편의를 위한 양식 자료입니다.
      </div>
    </div>

    <div class="Selection_card">
      <button @click = "defaultForm()" type="submit" class="btn btn-primary SubmitBtn">기본양식 다운로드</button>
    </div>
    <div class="Basic_Contents" >
      <div class="Desc_line" style="margin-bottom:0px;">
        아래 모든 선택 항목에서 (기본값) 내용을 적용한 양식 입니다.
      </div>
    </div>
    <br>
    <div class="Main_Contents">
      <div class="Selection_card" >
        <button @click = "downloadSample()" type="submit" class="btn btn-primary SubmitBtn" style="background-color:#10457f">선택양식 다운로드</button>
      </div>
      <div class="Basic_Contents">
        <div class="Desc_line" style="margin-bottom:0px;">
          아래의 선택 항목에 따라 변경된 리스자료 양식을 다운 받을 수 있습니다.<br>
          회사 상황에 맞춰 간편법 적용, 할인율 적용, 보증금 계산여부 등의 부가정보를 선택합니다.
          </div>
      </div>
      <form>
        <!--신규 디자인-->
        <b-row class="row_style">
          <b-col cols="5" @mouseover="showDescription[1] = true" @mouseleave="showDescription[1] = false" align-self="baseline">1. 사용권자산 감가상각 계정분류
            <span @click="toggleDescription(1)"  class="desc_detail">
            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" style="margin-top: -4px;" fill="currentColor" class="bi bi-question-circle" viewBox="0 0 16 16">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
              <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286m1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94"/>
            </svg></span>
          </b-col>
          <b-col cols="7" align-self="stretch">
            <div class="form-check">
            <input class="form-check-input" type="radio" name="form1" v-model="selected1" id="form1_1" value="0" checked>
            <label class="form-check-label" for="form1_1">
              (1) 감가상각비 : 판매관리비 또는 매출원가 중 선택 (기본값)
            </label>
          </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="form1" v-model="selected1" value="1" id="form1_2" >
              <label class="form-check-label" for="form1_2">
                (2) 감가상각비 판매관리비와 매출원가 비율 안분
              </label>
            </div>
          </b-col>
        </b-row>
        <div :class="{ 'show': showDescription[1] }" class="description" >
          사용권자산의 감가상각비는 판매관리비와 매출원가 중 한 가지를 선택할 수 있습니다.<br>
          사용권자산의 감가상각비를 일정 비율로 판매관리비와 매출원가 구분이 필요하신 경우 항목(2)를 선택하시기 바랍니다.
        </div>

        <b-row class="row_style">
          <b-col cols="5" @mouseover="showDescription[2] = true" @mouseleave="showDescription[2] = false" align-self="baseline">2. 리스부채 계산 방식 <span @click="toggleDescription(2)" class="desc_detail">
            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" style="margin-top: -4px;" fill="currentColor" class="bi bi-question-circle" viewBox="0 0 16 16">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
              <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286m1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94"/>
            </svg></span>
          </b-col>
          <b-col cols="7" align-self="stretch">
            <div class="form-check">
              <input class="form-check-input" type="radio" name="form2" v-model="selected2" id="form2_1" value="0" checked>
              <label class="form-check-label" for="form2_1">
                (1) 리스부채 월별 계산 (기본값)
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="form2" v-model="selected2" value="1" id="form2_2" >
              <label class="form-check-label" for="form2_2">
                (2) 리스부채 일별 계산
              </label>
            </div>
          </b-col>
        </b-row>
        <div :class="{ 'show': showDescription[2] }" class="description" >
          리스부채의 계산방식은 일반적으로 발생일자에 관계없이 리스개시월에 첫번째 월 지급액이 전액 발생한다고 가정하고 있습니다.<br>
          발생일자 기준으로 임차료 및 이자비용 발생을 일자계산하시려는 경우에는 항목(2)를 선택하시기 바랍니다.
        </div>

        <b-row class="row_style">
          <b-col cols="5" @mouseover="showDescription[3] = true" @mouseleave="showDescription[3] = false" align-self="baseline">3. 리스보증금 리스계산 <span @click="toggleDescription(3)" class="desc_detail">
            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" style="margin-top: -4px;" fill="currentColor" class="bi bi-question-circle" viewBox="0 0 16 16">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
              <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286m1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94"/>
            </svg></span>
          </b-col>
          <b-col cols="7" align-self="stretch">
            <div class="form-check">
              <input class="form-check-input" type="radio" name="form3" v-model="selected3" id="form3_1" value="0" checked>
              <label class="form-check-label" for="form3_1">
                (1) 보증금 리스계산 불포함(기본값)
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="form3" v-model="selected3" value="1" id="form3_2" >
              <label class="form-check-label" for="form3_2">
                (2) 보증금 리스계산 포함
              </label>
            </div>
          </b-col>
        </b-row>
        <div :class="{ 'show': showDescription[3] }" class="description" >
          기본 양식에는 보증금 계산을 포함하지 않고 있습니다. <br>
          보증금 현할차 관련 리스계산이 필요하신 경우 항목(2)를 선택하시기 바랍니다.
        </div>

        <b-row class="row_style">
          <b-col cols="5" @mouseover="showDescription[4] = true" @mouseleave="showDescription[4] = false" align-self="baseline">4. 복구충당부채 리스계산 <span @click="toggleDescription(4)" class="desc_detail">
            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" style="margin-top: -4px;" fill="currentColor" class="bi bi-question-circle" viewBox="0 0 16 16">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
              <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286m1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94"/>
            </svg></span>
          </b-col>
          <b-col cols="7" align-self="stretch">
            <div class="form-check">
              <input class="form-check-input" type="radio" name="form4" v-model="selected4" id="form4_1" value="0" checked>
              <label class="form-check-label" for="form4_1">
                (1) 복구충당부채 리스계산 불포함(기본값)
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="form4" v-model="selected4" value="1" id="form4_2" >
              <label class="form-check-label" for="form4_2">
                (2) 복구충당부채 리스계산 포함
              </label>
            </div>
          </b-col>
        </b-row>
        <div :class="{ 'show': showDescription[4] }" class="description" >
          기본 양식에는 복구충당부채 계산을 포함하지 않고 있습니다. <br>
          복구충당부채 관련 리스계산이 필요하신 경우 항목(2)를 선택하시기 바랍니다.
        </div>

        <b-row class="row_style">
          <b-col cols="5" @mouseover="showDescription[5] = true" @mouseleave="showDescription[5] = false" align-self="baseline">5. 외화리스 계산 <span @click="toggleDescription(5)" class="desc_detail">
            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" style="margin-top: -4px;" fill="currentColor" class="bi bi-question-circle" viewBox="0 0 16 16">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
              <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286m1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94"/>
            </svg></span>
          </b-col>
          <b-col cols="7" align-self="stretch">
            <div class="form-check">
              <input class="form-check-input" type="radio" name="form5" v-model="selected5" id="form5_1" value="0" checked>
              <label class="form-check-label" for="form5_1">
                (1) 외화리스 리스계산 불포함(기본값)
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="form5" v-model="selected5" value="1" id="form5_2" >
              <label class="form-check-label" for="form5_2">
                (2) 외화리스 리스계산 포함
              </label>
            </div>
          </b-col>
        </b-row>
        <div :class="{ 'show': showDescription[5] }" class="description" >
          기본 양식에는 외화로 지급하는 해외지점 등의 리스계산을 포함하지 않고 있습니다.<br>
          KRW가 아닌 외화로 지급하는 리스계산이 필요하신 경우 항목(2)를 선택하시기 바랍니다.
        </div>

        <b-row class="row_style">
          <b-col cols="5" @mouseover="showDescription[6] = true" @mouseleave="showDescription[6] = false" align-self="baseline">6. 기간별 리스료 직접 입력 <span @click="toggleDescription(6)" class="desc_detail">
            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" style="margin-top: -4px;" fill="currentColor" class="bi bi-question-circle" viewBox="0 0 16 16">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
              <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286m1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94"/>
            </svg></span>
          </b-col>
          <b-col cols="7" align-self="stretch">
            <div class="form-check">
              <input class="form-check-input" type="radio" name="form6" v-model="selected6" id="form6_1" value="0" checked>
              <label class="form-check-label" for="form6_1">
                (1) 단일 고정 리스료로 계산(기본값)
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="form6" v-model="selected6" value="1" id="form6_2" >
              <label class="form-check-label" for="form6_2">
                (2) 기간에 따라 고정리스료 변동
              </label>
            </div>
          </b-col>
        </b-row>
        <div :class="{ 'show': showDescription[6] }" class="description" >
          기본 양식은 매 기간의 리스료가 동일하다고 가정하여 계산을 수행하였습니다.<br>
          매 기간의 고정리스료에 변동이 있을 경우 항목 (2)를 선택하시기 바랍니다.<br>
          (예시 : 1차년도 1백만원, 2차년도 1.3백만원과 같이 고정 리스료가 변동되는 경우)
        </div>

        <b-row class="row_style">
          <b-col cols="5" @mouseover="showDescription[7] = true" @mouseleave="showDescription[7] = false" align-self="baseline">7. 전대리스 계산 <span @click="toggleDescription(7)" class="desc_detail">
            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" style="margin-top: -4px;" fill="currentColor" class="bi bi-question-circle" viewBox="0 0 16 16">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
              <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286m1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94"/>
            </svg></span>
          </b-col>
          <b-col cols="7" align-self="stretch">
            <div class="form-check">
              <input class="form-check-input" type="radio" name="form7" v-model="selected7" id="form7_1" value="0" checked>
              <label class="form-check-label" for="form7_1">
                (1) 전대리스 리스계산 불포함(기본값)
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="form7" v-model="selected7" value="1" id="form7_2" >
              <label class="form-check-label" for="form7_2">
                (2) 전대리스 리스계산 포함
              </label>
            </div>
          </b-col>
        </b-row>
        <div :class="{ 'show': showDescription[7] }" class="description" >
          기본 양식은 전대리스를 리스 계산에 고려하지 않고 있습니다.<br>
          만약 전대리스 관련 리스계산이 필요하신 경우 항목 (2)를 선택하시기 바랍니다.
        </div>

        <b-row class="row_style">
          <b-col cols="5" @mouseover="showDescription[8] = true" @mouseleave="showDescription[8] = false" align-self="baseline">8. 리스인센티브 여부 <span @click="toggleDescription(8)" class="desc_detail">
            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" style="margin-top: -4px;" fill="currentColor" class="bi bi-question-circle" viewBox="0 0 16 16">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
              <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286m1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94"/>
            </svg></span>
          </b-col>
          <b-col cols="7" align-self="stretch">
            <div class="form-check">
              <input class="form-check-input" type="radio" name="form8" v-model="selected8" id="form8_1" value="0" checked>
              <label class="form-check-label" for="form8_1">
                (1) 리스인센티브 리스계산 불포함(기본값)
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="form8" v-model="selected8" value="1" id="form8_2" >
              <label class="form-check-label" for="form8_2">
                (2) 리스인센티브 리스계산 포함
              </label>
            </div>
          </b-col>
        </b-row>
        <div :class="{ 'show': showDescription[8] }" class="description">
          기본 양식은 리스인센티브를 계산에 고려하고 있지 않습니다.<br>
          리스인센티브를 고려하여 리스를 계산하시려는 경우에는 항목 (2)를 선택하시기 바랍니다.
        </div>

        <b-row class="row_style">
          <b-col cols="5" @mouseover="showDescription[9] = true" @mouseleave="showDescription[9] = false" align-self="baseline">9. 해외 자회사 리스 계산 <span @click="toggleDescription(9)" class="desc_detail">
            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" style="margin-top: -4px;" fill="currentColor" class="bi bi-question-circle" viewBox="0 0 16 16">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
              <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286m1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94"/>
            </svg></span>
          </b-col>
          <b-col cols="7" align-self="stretch">
            <div class="form-check">
              <input class="form-check-input" type="radio" name="form9" v-model="selected9" id="form9_1" value="0" checked>
              <label class="form-check-label" for="form9_1">
                (1) 해외자회사 리스계산 불포함(기본값)
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="form9" v-model="selected9" value="1" id="form9_2" >
              <label class="form-check-label" for="form9_2">
                (2) 해외자회사 리스계산 포함(표시통화와 기능통화가 불일치하는 경우)
              </label>
            </div>
          </b-col>
        </b-row>
        <div :class="{ 'show': showDescription[9] }" class="description" >
          기본 양식은 한가지의 표시통화로 계산되는 리스 회계처리만을 제공하고 있습니다.<br>
          표시통화와 기능통화가 다른 해외 자회사 리스를 고려하여 리스를 계산하시려는 경우에는 항목 (2)를 선택하시기 바랍니다.
        </div>


        <b-row class="row_style">
          <b-col cols="5" @mouseover="showDescription[10] = true" @mouseleave="showDescription[10] = false" align-self="baseline">10. 리스 변동/취소 관리 <span @click="toggleDescription(10)" class="desc_detail">
            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" style="margin-top: -4px;" fill="currentColor" class="bi bi-question-circle" viewBox="0 0 16 16">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
              <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286m1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94"/>
            </svg></span>
          </b-col>
          <b-col cols="7" align-self="stretch">
            <div class="form-check">
              <input class="form-check-input" type="radio" name="form10" v-model="selected10" id="form10_1" value="0" checked>
              <label class="form-check-label" for="form10_1">
                (1) 별도의 리스 변경 및 취소 시트 미사용(기본값)
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="form10" v-model="selected10" value="1" id="form10_2" >
              <label class="form-check-label" for="form10_2">
                (2) 별도의 리스 변경 및 취소 시트 사용
              </label>
            </div>
          </b-col>
        </b-row>
        <div :class="{ 'show': showDescription[10] }" class="description" >
          기본 양식은 하나의 리스에서 별도의 변동과 취소를 관리하지 않습니다.<br>
          다만 보증금, 복구충당부채, 리스인센티브 등의 계산 진행과 같이 리스의 연장이 발생하는 경우에는 리스 변동에 대한 개별적인 관리를 필요로 하므로<br>
          보다 복잡한 리스의 연장 관리 차원에서 개별 리스에 대한 변동과 취소를 관리하고 싶은 경우 항목 (2)를 선택하시기 바랍니다.

        </div>

        <br>
      </form>



    </div>


  </div>

</template>

<script>



export default {
  name: "lease_form",
  data(){
    return {
      selected1:'0',
      selected2:'0',
      selected3:'0',
      selected4:'0',
      selected5:'0',
      selected6:'0',
      selected7:'0',
      selected8:'0',
      selected9:'0',
      selected10:'0',
      showDescription: {} //

    }
  },
  mounted(){
    if(localStorage.getItem("leaseForm")!=null){
      this.setForm()
    }
  },

  methods: {
    toggleDescription(index) {
      // 클릭한 항목의 설명을 토글합니다.
      this.showDescription[index] = !this.showDescription[index];
    },

    defaultForm(){
      // this.$gtag.event('LeaseForm(D)Click',{
      //   event_category: 'Clicks',
      //   event_label: 'Counts',
      //   value: 'DN LeaseF Default'
      // })

      // const checkItems = document.querySelectorAll('.row_style');
      // const zeros = Array(checkItems.length).fill(0).join('');

      const zeros = 0

      const url = '/lease/getInputFile/'+zeros
      const utoken =$store.state.token != null ? true : false;

      $api.getApiData(url,utoken,successStat,errorStat);
      function successStat (res) {
        localStorage.setItem("leaseForm", zeros);
        window.location.href = $store.state.basedomain+url

      }
      function errorStat (error) {
        alert("오류가 발생하였습니다. 페이지를 새로고침 해주세요.")
      }

    },

    setForm(){

      const ls_Form = localStorage.getItem("leaseForm")
      this.selected1 = ls_Form[0]
      this.selected2 = ls_Form[1]
      this.selected3 = ls_Form[2]
      this.selected4 = ls_Form[3]
      this.selected5 = ls_Form[4]
      this.selected6 = ls_Form[5]
      this.selected7 = ls_Form[6]
      this.selected8 = ls_Form[7]
      this.selected9 = ls_Form[8]
      this.selected10 = ls_Form[9]

    },

    downloadSample() {
      // this.$gtag.event('LeaseForm(S)Click',{
      //   event_category: 'Clicks',
      //   event_label: 'Counts',
      //   value: 'DN LeaseF Selection'
      // })
      const a = this.selected1
      const b = this.selected2
      const c = this.selected3
      const d = this.selected4
      const e = this.selected5
      const f = this.selected6
      const g = this.selected7
      const h = this.selected8
      const i = this.selected9
      const j = this.selected10


      const filepath = a+b+c+d+e+f+g+h+i+j

      const t =this
      const url = '/lease/getInputFile/'+filepath
      const utoken =$store.state.token != null ? true : false;

      $api.getApiData(url,utoken,successStat,errorStat);
      function successStat (res) {
        localStorage.setItem("leaseForm", filepath);
        window.location.href = $store.state.basedomain+url

      }
      function errorStat (error) {
        alert("오류가 발생하였습니다. 페이지를 새로고침 해주세요.")
      }

    }

  }
}
</script>

<style scoped>
form{
  margin-bottom: 50px;
  padding-bottom: 150px;
}

.description {
  /* 숨길 때의 스타일 */
  border: 0px solid #d1d1d1;
  border-radius: 5px;
  width:800px;
  padding:0px 0px 0px 20px;
  margin-bottom: 30px;
  font-size: 13px;
  color:#358bcb;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease,
  padding 0.3s ease,
  border 0.3s ease; /* 애니메이션 속성 추가 */

}

.description.show {
  border: 1px solid #d1d1d1;
  padding:5px 0px 5px 20px;
  /* 보일 때의 스타일 */
  max-height: 1000px; /* 적당한 최대 높이로 설정 */
}

.desc_line {

}

.Basic_Contents {
  width: 700px;
  background-color: #f5f5f5;
  border-radius: 5px;
  padding:10px;
  padding-left: 20px;
  margin-bottom: 40px;
}

.row_style {
  margin-bottom: 15px;
  margin-top: 30px;
  margin-left:5px;
  border-top:1px solid #ebebeb;
  width:53%;
  padding-top: 20px
}

.align-self-baseline{
  font-size: 14px;
  font-weight: bold
}

.align-self-stretch{
  font-size:13px;
}



.desc_detail {
  font-size: 9px ;
  font-weight:100;
  padding: 1px 3px;
  border-radius:3px;
  cursor: pointer;

}

.form_title{
  display: none;
}

.Selection_card p {margin-bottom:10px; font-size: 14px; font-weight: bold}
.form_selector {
  background-color: #e9ecef;
  width:700px;
  height:50px;
  border-radius: 5px;
  padding: 12px 12px 8px 18px;
  margin-bottom:40px;
  font-size:13px;
}

.form-check-input:checked {
  background-color: #10263f;
  border-color: #122841;
}

.form-check {

  padding-top: 3px;

}

.form_title {
  padding-left: 20px;
  margin-bottom: 22px;
  font-size: 13px;
  margin-top: 15px;
}

.form_download{
  height: 200px;
}

.SubmitBtn {
  margin-bottom: 10px;
  background-color: #0e2238;
  width: 139px;
  height: 36px;
  text-align: center;
  padding-top: 7px;
  color: white;
  border-radius: 35px;
  font-size: 14px;
}

.selct_radio {
  display: flex;
}



</style>

<!--<style scoped>-->
<!--.Selection_card p {margin-bottom:10px; font-size: 14px; font-weight: bold}-->
<!--.form_selector {-->
<!--  background-color: #e9ecef;-->
<!--  width:1000px;-->
<!--  height:50px;-->
<!--  border-radius: 5px;-->
<!--  padding: 12px 12px 8px 18px;-->
<!--  margin-bottom:70px;-->
<!--  font-size:13px;-->
<!--}-->

<!--.form-check-input:checked {-->
<!--  background-color: #10263f;-->
<!--  border-color: #122841;-->
<!--}-->

<!--.form-check {-->
<!--  width:50%;-->
<!--  padding-top: 3px;-->

<!--}-->

<!--.form_title {-->
<!--  padding-left: 20px;-->
<!--  margin-bottom: 22px;-->
<!--  font-size: 13px;-->
<!--  margin-top: 15px;-->
<!--}-->

<!--.form_download{-->
<!--  height: 200px;-->
<!--}-->

<!--.SubmitBtn {-->
<!--  margin-bottom: 10px;-->
<!--  background-color: #0e2238;-->
<!--  width: 139px;-->
<!--  height: 43px;-->
<!--  text-align: center;-->
<!--  padding-top: 7px;-->
<!--  color: white;-->
<!--  border-radius: 35px;-->
<!--}-->

<!--.selct_radio {-->
<!--  display: flex;-->
<!--}-->

<!--.Basic_Contents {-->
<!--  width: 54%;-->
<!--  padding-bottom: 30px;-->
<!--  margin-bottom: 30px;-->
<!--  border-bottom: 1px solid #cbcbcb;-->
<!--}-->

<!--</style>-->